<template>
    <div class="shippingAddress">
        <div class="newly">新增收货地址</div>
        <div class="curDeliverTo">
            <span>当前配送至：</span>
            <div>
                <span>中国大陆</span>
                <span>
                    切换
                    <i class="el-icon-arrow-right"></i>
                </span>
            </div>
        </div>

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
            <el-form-item label="地址信息：" prop="addressInfo" required>
                <!-- <div class="region">
                    <el-select v-model="ruleForm.province" placeholder="省">
                        <el-option
                        v-for="item in provinceList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="ruleForm.city" placeholder="市">
                        <el-option
                        v-for="item in cityList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="ruleForm.county" placeholder="区县">
                        <el-option
                        v-for="item in countyList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div> -->
                <el-cascader
                v-model="ruleForm.addressInfo"
                :props="areaProps"
                @change="handleChange"></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址：" prop="address" required>
                <el-input type="textarea" v-model="ruleForm.address" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="收货人姓名：" prop="receiveUser" required>
                <el-input v-model="ruleForm.receiveUser" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="手机号码：" prop="telephone" required>
                <div class="phoneLine">
                    <el-select v-model="ruleForm.location" placeholder="">
                        <el-option
                        v-for="item in locationList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>

                    <el-input v-model="ruleForm.telephone" placeholder="请填写你的联系方式" autocomplete="off"></el-input>
                </div>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="ruleForm.isDefault"></el-checkbox>&nbsp;设为默认地址
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="saveAddress">&emsp;保存&emsp;</el-button>
            </el-form-item>
        </el-form>

        <div class="addressList">
            <el-table
                :data="tableData"
                style="width: 100%">
                <el-table-column
                    prop="receiveUser"
                    label="收货人"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="belongArea"
                    label="所在地区">
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="详细地址">
                </el-table-column>
                <el-table-column
                    prop="post"
                    label="邮编">
                </el-table-column>
                <el-table-column
                    prop="telephone"
                    label="电话/手机">
                </el-table-column>
                <el-table-column
                    prop=""
                    label="操作">
                    <template slot-scope="scope">
                        <div class="opes">
                            <span @click="clickEdit(scope.row)">修改</span>
                            <span @click="clickDelete(scope.row)">删除</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop=""
                    label="">
                    <template slot-scope="scope">
                        <div class="defaultOpe">
                            <el-button v-show="scope.row.isDefault=='Y'" plain type="danger">默认地址</el-button>
                            <span class="setDefault" v-show="scope.row.isDefault=='N'" @click="setDefault(scope.row)">设为默认</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import {getCityListAll,addReceiveAddress,editReceiveAddress,editDefaultReceiveAddress,
getReceiveAddressList,deleteReceiveAddress} from "@/axios/api"
export default {
    data(){
        return{
            ruleForm:{
                receiveUser:"",
                addressInfo:[],
                address:"",
                telephone:"",
                location:1,
                receiveAddressId:"",
                isDefault:false,
            },
            provinceList:[],
            cityList:[],
            countyList:[],
            locationList:[
                {
                    label:"中国大陆+86",
                    value:1,
                }
            ],
            rules:{
                address:[
                    { required: true, message: '请输入详细地址', trigger: 'blur' },
                ],
                receiveUser:[
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                telephone:[
                    { required: true, message: '请输入联系方式', trigger: 'blur' },
                ],
            },
            areaData:[],//省市区数据
            areaProps: {
                lazy: true,
                lazyLoad (node, resolve) {
                    const { level } = node;
                    // // console.log(node)
                    getCityListAll({
                        level:level+1,
                        pid:node.data?node.data.id:""
                    }).then(res => {
                        // // console.log("地区数据",res)
                        res.data.forEach(item=>{
                            item.label=item.name;
                            item.value=item.id;
                            if(level>=2){
                                item.leaf=true
                            }
                        })
                        // // console.log(res.data)
                        // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                        resolve(res.data);
                    }, 1000);
                }
            },

            tableData:[]
        }
    },
    methods: {
        handleChange(value){
            // console.log(value)
            // console.log(this.ruleForm.addressInfo)
        },
        // 获取省市区
        gainCityListAll(){
            getCityListAll({level:1}).then(res=>{
                // console.log("省市区",res)
            })
        },
        // 点击保存
        saveAddress(){
            if(!this.ruleForm.telephone){
                return
            }
            var obj={
                receiveUser:this.ruleForm.receiveUser,
                province:this.ruleForm.addressInfo[0],
                city:this.ruleForm.addressInfo[1],
                county:this.ruleForm.addressInfo[2],
                address:this.ruleForm.address,
                telephone:this.ruleForm.telephone,
                isDefault:this.ruleForm.isDefault?"Y":"N",
                receiveAddressId:this.ruleForm.receiveAddressId,
            }
            if(!this.ruleForm.receiveAddressId){
                // console.log("id不存在即为新增")
                addReceiveAddress(obj).then(res=>{
                    // console.log("新增收货地址结果",res);
                    if(res.code==200){
                        this.$message.success(res.msg||res.message);
                        this.gainAddressList();

                        // setTimeout(()=>{
                        //     this.ruleForm={
                        //         receiveUser:"",
                        //         addressInfo:[],
                        //         address:"",
                        //         telephone:"",
                        //         location:1,
                        //         receiveAddressId:"",
                        //         isDefault:false
                        //     }
                        // },500)
                        // this.ruleForm.address="";
                    }else{
                        this.$message(res.msg||res.message)
                    }
                })
            }else{
                editReceiveAddress(obj).then(res=>{
                    // console.log("编辑收货地址结果",res);
                    if(res.code==200){
                        this.$message.success(res.msg||res.message);
                        this.gainAddressList();

                        // setTimeout(()=>{
                        //     this.ruleForm={
                        //         receiveUser:"",
                        //         addressInfo:[],
                        //         address:"",
                        //         telephone:"",
                        //         location:1,
                        //         receiveAddressId:"",
                        //         isDefault:false
                        //     }
                        // },500)
                    }else{
                        this.$message(res.msg||res.message)
                    }
                })
            }
        },
        // 获取收货地址列表
        gainAddressList(){
            getReceiveAddressList().then(res=>{
                // console.log("收货地址列表",res);
                if(res.code==200){
                    res.data.forEach(item=>{
                        item.belongArea=item.provinceName+" "+item.cityName+" "+item.countyName
                    })
                    this.tableData=res.data;
                    
                    if(this.$route.query.addId){
                        for(var i=0;i<this.tableData.length;i++){
                            if(this.tableData[i].receiveAddressId==this.$route.query.addId){
                                this.clickEdit(this.tableData[i])
                                break
                            }
                        }
                    }
                }
            })
        },
        // 点击修改
        clickEdit(row){
            // console.log(row);
            this.ruleForm.receiveUser=row.receiveUser;
            this.ruleForm.telephone=row.telephone;
            this.ruleForm.address=row.address;
            this.ruleForm.receiveAddressId=row.receiveAddressId;
            this.ruleForm.addressInfo=[row.province,row.city,row.county];
            this.ruleForm.isDefault=row.isDefault=="Y"?true:false;
        },
        // 点击删除
        clickDelete(row){
            deleteReceiveAddress({receiveAddressId:row.receiveAddressId}).then(res=>{
                // console.log("删除地址结果",res);
                if(res.code==200){
                    this.$message.success(res.msg||res.message);
                    this.gainAddressList();
                }else{
                    this.$message(res.msg||res.message)
                }
            })
        },
        // 设置默认
        setDefault(row){
            editDefaultReceiveAddress({receiveAddressId:row.receiveAddressId}).then(res=>{
                // console.log("设为默认地址结果",res);
                if(res.code==200){
                    this.$message.success(res.msg||res.message);
                    this.gainAddressList();
                }else{
                    this.$message(res.msg||res.message)
                }
            })
        }
    },
    created() {
        // this.gainCityListAll();
        this.gainAddressList();
    },
}
</script>

<style lang="less" scoped>
.shippingAddress{
    .newly{
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
    }
    .curDeliverTo{
        margin-top: 20px;
        background: rgba(242, 242, 242, 1);//2422422421
        height: 36px;
        width: 500px;
        box-sizing: border-box;
        padding: 0 20px;
        display: flex;
        align-items: center;
        &>span{
            font-size: 14px;
            font-weight: 400;
            color: rgba(153, 153, 153, 1);
        }
        &>div{
            flex: 1;
            display: flex;
            justify-content: space-between;
            &>span:nth-child(1){
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 1);
            }
            &>span:nth-child(2){
                font-size: 14px;
                font-weight: 400;
                color: rgba(42, 130, 228, 1);
            }
        }
    }
    .el-form{
        margin-top: 20px;
        /deep/.el-form-item{
            .el-input{
                width: 396px;
            }
            .el-textarea{
                width: 396px;
                .el-textarea__inner{
                    resize: none;
                }
            }
            .phoneLine{
                .el-select{
                    .el-input{
                        width: 178px;
                    }
                }
                &>.el-input{
                    width: 206px;
                    margin-left: 12px;
                }
            }
        }
    }
    .addressList{
        margin-top: 60px;
        border: 1px solid #EBECF0;
        .el-table{
            /deep/.el-table__header-wrapper tr th{
                background: #EBECF0;
            }
            /deep/.el-table__body-wrapper{
                tbody{
                    tr:last-child{
                        border-bottom: none;
                    }
                }
            }
        }
        .opes{
            display: flex;
            align-items: center;
            &>span{
                padding-right: 10px;
                margin-right: 10px;
                border-right: 1px solid #EBECF0;
                cursor: pointer;
                &:last-child{
                    border-right: none;
                    padding-right: 0;
                    margin-right: 0;
                }
            }
        }
        .defaultOpe{
            .setDefault{
                cursor: pointer;
            }
        }
    }
}
</style>